import React, { useState } from "react";
import classnames from "classnames";
import Button, { ButtonGroup, ButtonType } from "components/Base/Button";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  EffectFade,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { isPc } from 'utils/utils'
import * as styles from "./index.module.less";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade]);

interface GropeSwiperItem {
  thumb?: string;
  thumbAlt?: string;
  title?: string;
  desc?: string;
  buttonGroup?: {
    buttonHref?: string;
    buttonText?: string;
    buttonType?: ButtonType;
    onClick?: () => void;
  }[];
}
interface GropeSwiperProps {
  className?: string;
  dataSource: GropeSwiperItem[];
}
const GropeSwiper: React.FC<GropeSwiperProps> = (props) => {
  const { className, dataSource } = props;
  const [mSwiper, setSwiper] = useState<SwiperCore>();
  const cnames = classnames(styles.GropeSwiper, className, {
    [styles.ShowPagination]: dataSource.length > 1
  });

  const hoverChange = (index: number) => {
    mSwiper?.slideTo(index);
  }

  const renderHoverNavigation = () => {
    return (
      <div className={styles.HoverNavigation}>
        {dataSource?.map((item, index) => <div key={item.title} className={`slide-${index}`} onMouseEnter={() => hoverChange(index)}></div>)}
      </div>
    );
  }

  return (
    <Swiper
      className={cnames}
      onSwiper={(swiper) => setSwiper(swiper)}
      pagination={{ clickable: true }}
      allowTouchMove={!isPc()}
      effect={isPc() ? "fade" : undefined}
      fadeEffect={{ crossFade: isPc() }}
      autoplay={{
        delay: 6000, // 6s 自动轮播
      }}
    >
      {dataSource?.map((item) => (
        <SwiperSlide className={styles.GropeSwiperSlide} key={item.title}>
          <div className={styles.GropeSwiperLeft}>
            <h3>{item.title}</h3>
            <img src={item.thumb} alt={item.thumbAlt} />
          </div>
          <div className={styles.GropeSwiperRight}>
            <h3>{item.title}</h3>
            <p>{item.desc}</p>
            <ButtonGroup className={styles.ButtonGroup}>
              {item.buttonGroup?.map((btnItem, index) => (
                <Button
                  key={index}
                  btnType={btnItem.buttonType || ButtonType.Primary}
                  href={btnItem.buttonHref}
                  target="_blank"
                >
                  {btnItem.buttonText || "体验 Demo"}
                </Button>
              ))}
            </ButtonGroup>
          </div>
        </SwiperSlide>
      ))}
      {renderHoverNavigation()}
    </Swiper>
  );
};

export default GropeSwiper;
