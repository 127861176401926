import * as React from 'react'
import classnames from 'classnames'
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.css'
import 'swiper/components/navigation/navigation.less'
import 'swiper/components/pagination/pagination.less'
import * as styles from './index.module.less'

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Autoplay])

interface SchemeItemProps {
  title?: string
  icon?: string
  alt?: string
  desc?: string
}

interface SchemeProps {
  className?: string
  dataSource: SchemeItemProps[]
  columnNum?: number
  slidesPerColumn?: number
  useSwiper?: boolean // 移动端swiper
  itemHeight?: number
}

const Scheme: React.FC<SchemeProps> = ({
  className,
  dataSource,
  columnNum = 4,
  slidesPerColumn = 3,
  useSwiper, // 移动端swiper
  itemHeight = 240,
}) => {
  if (useSwiper) {
    return (
      <div className={classnames(styles.Scheme, className)}>
        <Swiper
          className={classnames('Scheme-swiper', `column-num-${slidesPerColumn}`)}
          spaceBetween={20}
          slidesPerView={1}
          slidesPerColumn={slidesPerColumn}
          navigation
          pagination={{ clickable: true }}
          style={{ height: slidesPerColumn * itemHeight + 40 + 40 }}
        >
          {dataSource?.map((item) => (
            <SwiperSlide className="scheme-item" key={item.title} style={{ height: itemHeight }}>
              <div className="header">
                <img src={item.icon} alt={item.alt} />
                <h3>{item.title}</h3>
              </div>
              <p>{item.desc}</p>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    )
  }

  return (
    <div className={classnames(styles.Scheme, className)}>
      <ul className={`scheme-list column-num-${columnNum}`}>
        {dataSource?.map((item) => (
          <li className="scheme-item" key={item.title}>
            <div className="header">
              <img src={item.icon} alt={item.alt} />
              <h3>{item.title}</h3>
            </div>
            <p>{item.desc}</p>
          </li>
        ))}
      </ul>
    </div>
  )
}

interface SchemeSwiperProps {
  className?: string
  dataSource: SchemeItemProps[]
}
export const SchemeSwiper: React.FC<SchemeSwiperProps> = (props) => {
  const { className, dataSource } = props
  return (
    <Swiper className={classnames(styles.SchemeSwiper, className)} pagination={{ clickable: true }} autoHeight={true}>
      {dataSource?.map((item) => (
        <SwiperSlide className={styles.SchemeSwiperSlide} key={item.title}>
          <div className={styles.SchemeSwiperItem}>
            <div className={styles.SchemeSwiperHeader}>
              <img src={item.icon} alt={item.alt} />
              <h3>{item.title}</h3>
            </div>
            <p>{item.desc}</p>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

export default Scheme
