import React from 'react'
import Seo from 'components/seo'
import Layout from 'layouts/zh'
import Button, { ButtonGroup, ButtonGroupAlign, ButtonType } from 'components/Base/Button'
import SolutionsBanner from 'components/SolutionsBanner'
import SectionContent, { SectionHeaderMobile } from 'components/SectionContent'
import SolutionsSwiper from 'components/SolutionsSwiper'
import SolutionsSwiperMobile from 'components/SolutionsSwiperMobile'
import Scheme from 'components/Scheme'
import ScanCodeConsultButton from 'components/ScanCodeConsultButton'
import PageBottomCard from 'components/PageBottomCard'
import GropeSwiper from 'components/GropeSwiper'

import * as styles from './index.module.less'

const Ad: React.FC = (props) => {
  return (
    <Layout {...props}>
      <Seo
        title="神策广告分析｜全域深链路广告投放系统-神策数据产品"
        description="神策数据提供广告投放渠道的效果优化，通过全域、全触点、深链路的用户行为分析，降低业务人员广告分析门槛，进行实时投放数据效果监控，提高广告投放优化效率，实现精准化营销。"
        keywords="广告投放分析平台,广告精准投放分析,信息流广告投放分析,用户画像分析,用户行为分析"
        saTitle="首页-产品（广告投放分析）"
      />
      <AdState />
    </Layout>
  )
}

const AdState: React.FC = () => {
  const demoUrl = '/doMatrixSat?formpage_source2=SAT'
  const paradeUrl = '/form/parade.html?formpage_source2=SAT预约演示'
  const qrcodeUrl = require('assets/images/solution/ad/wxcode.png').default

  return (
    <main>
      <SolutionsBanner
        className={styles.BannerWrap}
        title="神策广告分析"
        subTitle="全域深链路广告投放分析系统"
        desc={
          '拉通公私域、全触点、深链路的用户行为数据，结合多维度报表及分析模型，满足\n用户洞察、广告渠道归因分析，品牌资产沉淀的需求'
        }
        imgUrl={
          <img
            src={require('assets/images/solution/ad/icon-banner.png').default}
            alt="神策广告分析"
            className="banner-icon"
          />
        }
        imgWidth={473}
        buttonGroup={
          <ButtonGroup>
            <Button href={demoUrl} target="_blank">
              体验 Demo
            </Button>
            <ScanCodeConsultButton
              buttonType={ButtonType.Ghost}
              buttonText="扫码咨询"
              qrcode={qrcodeUrl}
              style={{ marginLeft: 10 }}
            />
          </ButtonGroup>
        }
        mobileHideIcon
        mobileHideButton
        mobileHideDesc
      />
      <SectionContent title="“全域 + 深链路”的广告效果监测分析平台" useMobileHeader hasMobilePadding>
        <SectionHeaderMobile title="“全域 + 深链路”的广告效果监测分析平台" />
        <SolutionsSwiper
          className={styles.SolutionsSwiper}
          tabWidth={222}
          dataSource={[
            {
              icon: 'output',
              tabText: <>跨渠道广告效果分析</>,
              title: '跨渠道广告效果分析',
              imgUrl: require('assets/images/solution/ad/feature_01.png').default,
              imgWidth: 590,
              descList: [
                {
                  title: '跨渠道数据概览',
                  list: ['对接主流媒体平台 Marketing API 同步投放数据，按需定制仪表盘，广告效果与关键数据一目了然'],
                },
                {
                  title: '全域数据多维分析模型',
                  list: [
                    '支持 LTV / CLV、多层转化分析及其他自定义分析模型，支持多维度、细颗粒的交叉分析，满足广告效果评估、用户特征洞察、精细化运营等需求',
                  ],
                },
              ],
              buttonList: [
                {
                  text: '体验 Demo',
                  href: demoUrl,
                  target: '_blank',
                  type: 'primary',
                },
              ],
            },
            {
              icon: 'report',
              tabText: <>流量渠道价值评估</>,
              title: '流量渠道价值评估',
              imgUrl: require('assets/images/solution/ad/feature_02.png').default,
              imgWidth: 590,
              descList: [
                {
                  title: '识别高价值流量渠道',
                  list: ['媒介“展”、“点”、“消”数据，结合渠道归因分析能力，识别高价值传播通路'],
                },
                {
                  title: '用户来源感知',
                  list: ['多维度细颗粒交叉分析，识别用户触媒偏好，为不同转化指标匹配合适的投放策略'],
                },
              ],
              buttonList: [
                {
                  text: '体验 Demo',
                  href: demoUrl,
                  target: '_blank',
                  type: 'primary',
                },
              ],
            },
            {
              icon: 'user-insight',
              tabText: <>受众分析洞察</>,
              title: '受众分析洞察',
              imgUrl: require('assets/images/solution/ad/feature_03.png').default,
              imgWidth: 590,
              descList: [
                {
                  title: '全域深链数据整合',
                  list: [
                    '神策 One ID 方案，打破公域媒介数据和私域用户行为数据的壁垒，统一用户标识，支持全链路转化效果分析',
                  ],
                },
                {
                  title: '受众画像洞察',
                  list: [
                    '支持不同转化层级的广告受众分析，精细化还原高价值受众画像，支持全域精准拓客策略，提升获客效率，优化获客成本',
                  ],
                },
              ],
              buttonList: [
                {
                  text: '体验 Demo',
                  href: demoUrl,
                  target: '_blank',
                  type: 'primary',
                },
              ],
            },
          ]}
        />
        <SolutionsSwiperMobile
          className={styles.SolutionsSwiperMobile}
          dataSource={[
            {
              title: '跨渠道广告效果分析',
              imgUrl: require('assets/images/solution/ad/feature_mb_01.png').default,
            },
            {
              title: '流量渠道价值评估',
              imgUrl: require('assets/images/solution/ad/feature_mb_02.png').default,
            },
            {
              title: '受众分析洞察',
              imgUrl: require('assets/images/solution/ad/feature_mb_03.png').default,
            },
          ]}
        />
        <ButtonGroup className={styles.SolutionsSwiperMobileButton} align={ButtonGroupAlign.Center}>
          <Button btnType={ButtonType.Primary} href={demoUrl} target="_blank">
            体验 Demo
          </Button>
        </ButtonGroup>
      </SectionContent>
      <SectionContent
        className={styles.GropeWrap}
        title="流量转化分析闭环，提升转化效率"
        backgroundColor="#F8FBFD"
        useMobileHeader
        hasMobilePadding
      >
        <SectionHeaderMobile title="流量转化分析闭环，提升转化效率" />
        <GropeSwiper
          className={styles.GropeSwiper}
          dataSource={[
            {
              thumb: require('assets/images/solution/ad/g-01.png').default,
              thumbAlt: '用户洞察与人群策略',
              title: '用户洞察与人群策略',
              desc: '广告受众的媒介来源及站内转化效果分析，帮您识别高价值传播通路；\n基于核心用户洞察生成的特征人群包，赋能媒体广告投放的精准目标人群触达，提升广告转化率',
              buttonGroup: [
                {
                  buttonHref: demoUrl,
                  buttonText: '体验 Demo',
                },
              ],
            },
            {
              thumb: require('assets/images/solution/ad/g-02.png').default,
              thumbAlt: '渠道管理与回传调优',
              title: '渠道管理与回传调优',
              desc: '支持账号、广告计划、广告组、广告创意维度分析报表，帮助您提升广告投放效率，优化媒体营销效果；\n支持深度事件回传，将用户特征信息回传给媒体，优化广告投放模型',
              buttonGroup: [
                {
                  buttonHref: demoUrl,
                  buttonText: '体验 Demo',
                },
              ],
            },
            {
              thumb: require('assets/images/solution/ad/g-03.png').default,
              thumbAlt: '渠道监测与归因分析',
              title: '渠道监测与归因分析',
              desc: '公私域数据“一表”拉通，广告仪表盘实时监测媒体渠道指标变化；\n挖掘用户加购、收藏、首购、复购等深度行为，通过归因分析了解广告投放 ROI，洞察不同渠道来源用户的价值',
              buttonGroup: [
                {
                  buttonHref: demoUrl,
                  buttonText: '体验 Demo',
                },
              ],
            },
            {
              thumb: require('assets/images/solution/ad/g-04.png').default,
              thumbAlt: '用户人群画像优化',
              title: '数据资产沉淀与人群画像优化',
              desc: '基于单一用户 ID 的数据匹配，帮助品牌沉淀数据资产，通过广告受众后链路行为的深度挖掘，不断完善一方用户人群画像，以终为始优化广告投放',
              buttonGroup: [
                {
                  buttonHref: demoUrl,
                  buttonText: '体验 Demo',
                },
              ],
            },
          ]}
        />
        <ButtonGroup className={styles.GropeWrapMobileButton} align={ButtonGroupAlign.Center}>
          <Button btnType={ButtonType.Primary} href={demoUrl} target="_blank">
            体验 Demo
          </Button>
        </ButtonGroup>
      </SectionContent>
      <SectionContent className={styles.SchemeContainer} title="为什么选择神策？">
        <Scheme
          columnNum={4}
          dataSource={[
            {
              title: '实时准确的\n投放监测能力',
              desc: '神策扎实的数据技术能力保证广告投放数据同步的准确性和实时性',
              icon: require('assets/images/solution/ad/t-01.svg').default,
              alt: '',
            },
            {
              title: '直观高效的\n分析模型',
              desc: '用户留存、LTV 分析模型帮助您轻松上手媒体投放策略评估',
              icon: require('assets/images/solution/ad/t-02.svg').default,
              alt: '',
            },
            {
              title: '强大的数据整合\n打通能力',
              desc: '神策强大的 One ID 能力打通公域、私域营销数据通路，为多维度、深层次广告效果分析夯实基础',
              icon: require('assets/images/solution/ad/t-03.svg').default,
              alt: '',
            },
            {
              title: '严格的隐私保护\n策略',
              desc: '神策郑重承诺未经授权不接触客户数据，致力于为客户以及客户用户数据提供严格的隐私保护',
              icon: require('assets/images/solution/ad/t-04.svg').default,
              alt: '',
            },
          ]}
        />
        <ButtonGroup className={styles.ButtonQrcodeWrap} align={ButtonGroupAlign.Center}>
          <ScanCodeConsultButton buttonText="扫码咨询" qrcode={qrcodeUrl} />
        </ButtonGroup>
      </SectionContent>
      <PageBottomCard
        title="通过“全域 + 深链路”的数据分析，驱动广告投放策略迭代"
        desc="立即体验，感受广告全链路数据分析视角带来的效率提升！"
        leftButtonText="体验 Demo"
        leftButtonHref={demoUrl}
        rightButtonText="预约演示"
        rightButtonHref={paradeUrl}
      />
    </main>
  )
}

export default Ad
