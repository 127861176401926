import * as React from 'react'
import classnames from 'classnames'
import Button, { ButtonGroup, ButtonGroupAlign } from '../Base/Button'
import ScanCodeConsultButton from 'components/ScanCodeConsultButton'
import * as styles from './index.module.less'

interface PageBottomCardProps {
  className?: string
  title?: string
  desc?: string
  leftButtonText?: string
  leftButtonHref?: string
  rightButtonText?: string
  rightButtonHref?: string
  onClickLeftButton?: React.MouseEventHandler<HTMLElement>
  children?: any
  qrButton?: any
}

const PageBottomCard: React.FC<PageBottomCardProps> = ({
  className,
  title,
  desc,
  leftButtonText,
  leftButtonHref,
  rightButtonText,
  rightButtonHref,
  onClickLeftButton,
  children,
  qrButton,
}) => {
  const style = {
    PageBottomCard: `relative h-auto lg:h-[270px] !pt-[4rem] !pb-[3rem] !px-0  lg:py-[60px] px-0 bg-primary lg:bg-[url('assets/images/page-bottom-bg.png')] bg-no-repeat bg-[length:1200px_431px] bg-[center_-45px] 
    before:block before:absolute before:absolute before:h-[6rem] before:w-[13.8rem] before:bg-[url('assets/images/bottom-icon-mb.png')] before:bg-[length:100%_100%]
    after:content after:block after:absolute after:absolute after:h-[6rem] after:w-[13.8rem] after:bg-[url('assets/images/bottom-icon-mb.png')] after:bg-[length:100%_100%] 
    before:content
    before:top-[-1.3rem] before:right-[1.4rem] after:left-[2.6rem] after:bottom-0`,
    PageBottomCardContent: `px-[2rem] lg:p-0 max-w-[1200px] my-0 mx-auto text-center`,
    PageBottomCardTitle: `text-[2rem] lg:text-[28px] text-sd-light font-normal leading-[40px]`,
    PageBottomCardSubTitle: `text-[1.4rem] lg:text-[16px] text-sd-light font-normal leading-[2] lg:leading-[3rem] lg:leading-none ml-[1rem] mr-0 lg:mt-[10px]`,
  }
  return (
    <section className={classnames(style.PageBottomCard, 'page-bottom-card', className)}>
      <article className={style['PageBottomCardContent']}>
        <h2 className={style['PageBottomCardTitle']}>{title}</h2>
        <p className={style['PageBottomCardSubTitle']}>{desc}</p>
        <ButtonGroup align={ButtonGroupAlign.Center} className="mt-[1rem] lg:mt-[30px]">
          <Button
            className="px-[2rem] lg:px-[3rem]"
            href={leftButtonHref}
            target="_blank"
            onClick={onClickLeftButton}
            style={{ display: !leftButtonText ? 'none' : undefined }}
          >
            {leftButtonText}
          </Button>
          <Button
            className="px-[2rem] lg:px-[3rem]"
            btnType="ghost"
            ghost
            target="_blank"
            href={rightButtonHref}
            style={{ display: !rightButtonText ? 'none' : undefined }}
          >
            {rightButtonText}
          </Button>
        </ButtonGroup>
        {qrButton && (
          <ScanCodeConsultButton
            className="px-[2rem] lg:px-[3rem]"
            buttonType={qrButton.buttonType}
            ghost
            buttonText={qrButton.buttonText}
            qrcode={qrButton.qrcode}
            alt={qrButton.alt}
            imgBottomText={qrButton.imgBottomText}
          />
        )}
        {children}
      </article>
    </section>
  )
}

export default PageBottomCard
