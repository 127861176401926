import React from "react"
import classnames from "classnames"
import Button from 'components/Base/Button'
import SwiperCore, { Pagination, Scrollbar, A11y, Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import * as styles from './index.module.less'

SwiperCore.use([Pagination, Scrollbar, A11y, Autoplay, Navigation]);

interface SolutionsSwiperProps {
  className?: string;
  dataSource?: {
    title?: string;
    imgUrl?: string;
  }[]
}

const SolutionsSwiperMobile: React.FC<SolutionsSwiperProps> = (props) => {
  const {
    className,
    dataSource
  } = props;
  return (
    <Swiper 
      pagination={{clickable: true}}
      className={classnames(styles.SolutionsSwiperMobile, 'solutions-swiper-mobile', className)}
      allowTouchMove={true}
      autoplay={{
        delay: 6000
      }}
    >
      {dataSource?.map((item: any, index: number) => <SwiperSlide key={index}>
        <h3>{item?.title}</h3>
        <img src={item.imgUrl} alt="" />
      </SwiperSlide>)}
    </Swiper>
  );
}

export default SolutionsSwiperMobile;