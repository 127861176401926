/* eslint-disable */
import React from 'react'
import classnames from 'classnames'
import Button, { ButtonGroup, ButtonProps } from 'components/Base/Button'
import SwiperCore, { Pagination, Scrollbar, A11y, Autoplay, Navigation } from 'swiper'
import ScanCodeConsultButton from 'components/ScanCodeConsultButton'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.css'
import { isPc } from 'utils/utils'
import * as styles from './index.module.less'

const { useState, useRef } = React
SwiperCore.use([Pagination, Scrollbar, A11y, Autoplay, Navigation])

interface descItemProps {
  title?: string
  list?: string[]
}
interface ButtonItemProps {
  text?: string
  href?: string
  target?: string
  className?: string
  type?: string
  onClick?: () => void
}
interface QrButtonProps {
  buttonType?: string
  buttonText?: string
  qrcode?: string
  alt?: string
  imgBottomText?: string
}
interface ItemProps {
  className?: string
  icon?: string
  tabText?: React.ReactNode | string
  title?: string
  titleSmall?: string
  subTitle?: string
  imgUrl?: string
  imgWidth?: number
  descList?: descItemProps[]
  buttonList?: ButtonItemProps[]
  qrButton?: QrButtonProps
}
interface SolutionsSwiperProps {
  className?: string
  tabWidth?: number
  dataSource?: ItemProps[]
}

const SolutionsSwiper: React.FC<SolutionsSwiperProps> = ({ className, tabWidth, dataSource = [] }) => {
  const [state, setState] = useState({
    current: 0,
    data: [],
  })
  const mSwiper = useRef<SwiperCore>()

  const updateSwiperCurrent = (index: number) => {
    setState({
      ...state,
      current: index,
    })
  }

  const handleMouseOver = (index: number) => {
    mSwiper?.current?.autoplay.stop()
    updateSwiperCurrent(index)
    mSwiper && mSwiper?.current?.slideTo(index, 300, false)
  }

  const handleMouseOut = () => {
    mSwiper?.current?.autoplay.start()
  }

  return (
    <div className={classnames(styles.WholeDomain, className)}>
      <ul className="tab-menu">
        {dataSource?.map((item: any, index: number) => (
          <li
            key={index}
            className={classnames(item.className, item.icon, state.current === index ? 'active' : '')}
            onMouseOver={() => handleMouseOver(index)}
            onMouseOut={() => handleMouseOut()}
          >
            <i className="icon"></i>
            <span style={{ width: tabWidth }}>{item.tabText || item.title}</span>
          </li>
        ))}
      </ul>
      <Swiper
        className="WholeDomain-swiper"
        onSwiper={(swiper) => (mSwiper.current = swiper)}
        onSlideChange={() => updateSwiperCurrent(mSwiper?.current?.activeIndex as number)}
        autoHeight={isPc() ? false : true}
        allowTouchMove={isPc() ? false : true}
        autoplay={{
          delay: 6000,
        }}
      >
        {dataSource?.map((item: ItemProps) => (
          <SwiperSlide key={item.title} className={classnames(item.className)}>
            <div className="left">
              <h3 className="solutions-swiper-title">{item?.title}</h3>
              <p
                className={classnames('solutions-swiper-title-small', {
                  show: item?.titleSmall,
                })}
              >
                {item?.titleSmall}
              </p>
              <h5
                className={classnames('solutions-swiper-subtitle', {
                  show: item?.subTitle,
                })}
              >
                {item?.subTitle}
              </h5>
              <ul>
                {item?.descList?.map((descItem: descItemProps) => (
                  <li className={classnames({ 'no-desc': !descItem?.list })} key={descItem.title}>
                    <h4>{descItem.title}</h4>
                    <div className="desc">
                      {descItem?.list?.map((listItem: string) => (
                        <p key={listItem}>{listItem}</p>
                      ))}
                    </div>
                  </li>
                ))}
              </ul>
              <ButtonGroup>
                {item?.buttonList?.map((buttonItem: any) => (
                  <Button
                    onClick={buttonItem.onClick}
                    key={buttonItem.text}
                    className={buttonItem.className}
                    btnType={buttonItem.type}
                    href={buttonItem.href}
                    target={buttonItem.target}
                  >
                    {buttonItem.text}
                  </Button>
                ))}
              </ButtonGroup>
              {item.qrButton && (
                <div className="SolutionsSwiper-qrButton">
                  <ScanCodeConsultButton
                    buttonType={item.qrButton?.buttonType}
                    // ghost
                    buttonText={item.qrButton?.buttonText}
                    qrcode={item.qrButton?.qrcode}
                    alt={item.qrButton?.alt}
                    imgBottomText={item.qrButton?.imgBottomText}
                  />
                </div>
              )}
            </div>
            <div className="right" style={{ width: item.imgWidth }}>
              <img src={item.imgUrl} alt={item.title} />
              <ButtonGroup>
                {item?.buttonList?.map((buttonItem: ButtonItemProps) => (
                  <Button
                    onClick={buttonItem.onClick}
                    key={buttonItem.text}
                    className={buttonItem.className}
                    btnType={buttonItem.type}
                    href={buttonItem.href}
                    target={buttonItem.target}
                  >
                    {buttonItem.text}
                  </Button>
                ))}
              </ButtonGroup>
              {item.qrButton && (
                <div className="SolutionsSwiper-qrButton">
                  <ScanCodeConsultButton
                    buttonType={item.qrButton?.buttonType}
                    // ghost
                    buttonText={item.qrButton?.buttonText}
                    qrcode={item.qrButton?.qrcode}
                    alt={item.qrButton?.alt}
                    imgBottomText={item.qrButton?.imgBottomText}
                  />
                </div>
              )}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default SolutionsSwiper
/* eslint-disable no-new */
