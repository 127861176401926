import React from "react"
import classnames from 'classnames'
import * as styles from './index.module.less'

interface SolutionsBannerProps {
  title?: string;
  subTitle?: string;
  desc?: string;
  buttonGroup?: any;
  imgUrl?: any;
  imgWidth?: number;
  imgHeight?: number;
  style?: any;
  className?: string;
  mobileHideIcon?: boolean;
  mobileHideButton?: boolean;
  mobileHideDesc?: boolean;
}

const SolutionsBanner: React.FC<SolutionsBannerProps> = ({
  title,
  subTitle,
  desc,
  buttonGroup,
  imgUrl,
  style,
  className,
  mobileHideIcon,
  mobileHideButton,
  mobileHideDesc,
  imgWidth,
  imgHeight
}) => {
  const cnames = classnames(styles.SolutionsBanner, className, {
    [styles.hideRight]: mobileHideIcon
  });
  const subCnames = classnames({
    'mobile-subtitle': mobileHideIcon
  });
  return (
    <div className={cnames} style={style}>
      <article>
        <div className="left">
          <h1>{title}</h1>
          {subTitle && <h2 className={subCnames}>{subTitle}</h2>}
          {desc && <p className={classnames({ 'mobile-hidden': mobileHideDesc })}>{desc}</p>}
          {buttonGroup && <div className={classnames("button-group", { 'mobile-hidden': mobileHideButton })}>
            {buttonGroup}
          </div>}
        </div>
        {imgUrl && <div 
          className={classnames("right", { 'mobile-hidden': mobileHideIcon })} 
          style={{width: imgWidth, height: imgHeight}}
        >
          {imgUrl}
        </div>}
      </article>
    </div>
  );
}

export default SolutionsBanner